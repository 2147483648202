exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-hello-world-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/hello-world/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-hello-world-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-unity-with-vim-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/unity-with-vim/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-unity-with-vim-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-petualangan-timun-mas-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/petualangan-timun-mas/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-petualangan-timun-mas-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-slime-jump-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/slime-jump/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-slime-jump-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-suika-clone-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/suika-clone/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-suika-clone-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-the-evil-wizard-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/the-evil-wizard/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-project-the-evil-wizard-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-linktree-js": () => import("./../../../src/pages/linktree.js" /* webpackChunkName: "component---src-pages-linktree-js" */),
  "component---src-pages-project-index-js": () => import("./../../../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-blog-hello-world-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/hello-world/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-blog-hello-world-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-blog-unity-with-vim-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/unity-with-vim/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-blog-unity-with-vim-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-petualangan-timun-mas-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/petualangan-timun-mas/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-petualangan-timun-mas-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-slime-jump-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/slime-jump/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-slime-jump-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-suika-clone-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/suika-clone/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-suika-clone-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-the-evil-wizard-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/project/the-evil-wizard/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-project-the-evil-wizard-index-mdx" */)
}

